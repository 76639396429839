import React, { useState } from "react";
import { Carousel, Row, Col } from "react-bootstrap";
import "../Carousal2/Carousal2.css";

function CarousalComponent2() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <section>
      <div className="p-5">
        {/* <Row className="text-center p-5">
          <Col>
            <h4 style={{ fontFamily: "sans-serif" }}>Happy Customers</h4>
          </Col>
        </Row> */}
        <Carousel variant="dark" className="p-5" controls={false}>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="images/banners/white.png"
              alt="First slide"
            />
            <Carousel.Caption>
              <h3 style={{ color: "black" }}>Tip Of The Day</h3>
              <p style={{ color: "black" }}>
                Nulla vitae elit libero, a pharetra augue mollis interdum.
              </p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="images/banners/white.png"
              alt="Second slide"
            />

            <Carousel.Caption>
              <h3 style={{ color: "black" }}>Tip Of The Day 2</h3>
              <p style={{ color: "black" }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="images/banners/white.png"
              alt="Third slide"
            />

            <Carousel.Caption>
              <h3 style={{ color: "black" }}>Tip Of The Day 3</h3>
              <p style={{ color: "black" }}>
                Praesent commodo cursus magna, vel scelerisque nisl consectetur.
              </p>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </div>
    </section>
  );
}

export default CarousalComponent2;
