import React, { useState, useEffect } from "react";
import "../Carousal/Carousal.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";

// import Swiper core and required modules
import SwiperCore, { Mousewheel, Pagination } from "swiper/core";

// install Swiper modules
SwiperCore.use([Mousewheel, Pagination]);

function CarousalComponent() {
  return (
    <>
      <Swiper
        direction={"horizontal"}
        slidesPerView={1}
        spaceBetween={0}
        className="mySwiper"
        mousewheel
      >
        <SwiperSlide>
          <img
            className="d-block w-100"
            src="images/banners/1.jpg"
            alt="First slide"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            className="d-block w-100"
            src="images/banners/2.jpg"
            alt="First slide"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            className="d-block w-100"
            src="images/banners/3.jpg"
            alt="First slide"
          />
        </SwiperSlide>
      </Swiper>
    </>
  );
}

export default CarousalComponent;
